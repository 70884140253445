import React from "react";
import WhatWeDo from "./WhatWeDo";
import HowWeDo from "./HowWeDo";
import Think from "../home/Think";
import {useStaticQuery, graphql} from "gatsby";
const Index = () => {
  const data = useStaticQuery(graphql`
    query about {
      allSanityAbout {
        edges {
          node {
            sections {
              ... on SanityContentSection {
                _type
                mention
                description
              }
            }
          }
        }
      }
    }
  `);
  const WhatWeDoTitle = data.allSanityAbout.edges[0].node.sections[0].mention;
  const WhatWeDoSubTitle = data.allSanityAbout.edges[0].node.sections[0].description;

  const HowWeDoTitle = data.allSanityAbout.edges[0].node.sections[2].mention;
  const HowWeDoDescription = data.allSanityAbout.edges[0].node.sections[2].description;
  return (
    <div>
      <WhatWeDo WhatWeDoTitle={WhatWeDoTitle} WhatWeDoSubTitle={WhatWeDoSubTitle}/>
      <HowWeDo HowWeDoTitle={HowWeDoTitle} HowWeDoDescription={HowWeDoDescription}/>
      <Think />
    </div>
  );
};

export default Index;
