export const aboutUsData = [
  {
    title: "Protect",
    description: "Back up for every professional circumstance.",
    logo: require("../images/What-we-do-asset-01.png").default,
  },
  {
    title: "Direct",
    description: "Guidance for when the best way forward is unclear",
    logo: require("../images/png-assets-05.png").default,
  },
  {
    title: "Enhance",
    description: "Best practices and processes to apply in your role.",
    logo: require("../images/png-assets-06.png").default,
  },
  {
    title: "Advance",
    description: "Support to achieve financial and professional success.",
    logo: require("../images/png-assets-07.png").default,
  },
];
export const aboutUsHowWeData = [
  {
    title: "Instant Connection",
    description:
      "Converse with other members in our exclusive Slack group or via email & phone..",
    content:
      "It’s awesome to have a safe space to ask questions, share from your experience and be inspired to keep getting better every day.",
    author: "CEO",
    authorName: "Megan Bowen",
    authorImg: require("../images/Megan-Bowen.jpeg").default,
    logo: require("../images/RC_how_we_do_it_icons-01.png").default,
  },
  {
    title: "Virtual Gatherings",
    description:
      "20+ digital offerings a week including webinars, conferences, happy hours, and podcasts.",
    content:
      '"I love the local networking events that allow me to connect with my peers at other companies and develop deep meaningful relationships."',
    author: "SVP, Head of Growth",
    authorName: "Brian Manning",
    authorImg: require("../images/Brian-Manning.jpeg").default,
    logo: require("../images/RC_how_we_do_it_icons-03.png").default,
  },
  {
    title: "Virtual Gatherings",
    description:
      "20+ digital offerings a week including webinars, conferences, happy hours, and podcasts.",
    content:
      '"I love the local networking events that allow me to connect with my peers at other companies and develop deep meaningful relationships."',
    author: "SVP, Head of Growth",
    authorName: "Brian Manning",
    authorImg: require("../images/Brian-Manning.jpeg").default,
    logo: require("../images/RC_how_we_do_it_icons-02.png").default,
  },
  {
    title: "Convening Power",
    description: "A stable of available members willing to provide counsel.",
    content:
      '"The group is small enough to feel intimate but large enough to have an impact."',
    author: "CMO",
    authorName: "Deirdra A. Colvin",
    authorImg: require("../images/Deirdra-Colvin.jpeg").default,
    logo: require("../images/RC_how_we_do_it_icons-04.png").default,
  },
  {
    title: "Instant Connection",
    description:
      "Converse with other members in our exclusive Slack group or via email & phone..",
    content:
      "It’s awesome to have a safe space to ask questions, share from your experience and be inspired to keep getting better every day.",
    author: "CEO",
    authorName: "Megan Bowen",
    authorImg: require("../images/Megan-Bowen.jpeg").default,
    logo: require("../images/RC_how_we_do_it_icons-01.png").default,
  },
  {
    title: "Virtual Gatherings",
    description:
      "20+ digital offerings a week including webinars, conferences, happy hours, and podcasts.",
    content:
      '"I love the local networking events that allow me to connect with my peers at other companies and develop deep meaningful relationships."',
    author: "SVP, Head of Growth",
    authorName: "Brian Manning",
    authorImg: require("../images/Brian-Manning.jpeg").default,
    logo: require("../images/RC_how_we_do_it_icons-03.png").default,
  },
  {
    title: "Virtual Gatherings",
    description:
      "20+ digital offerings a week including webinars, conferences, happy hours, and podcasts.",
    content:
      '"I love the local networking events that allow me to connect with my peers at other companies and develop deep meaningful relationships."',
    author: "SVP, Head of Growth",
    authorName: "Brian Manning",
    authorImg: require("../images/Brian-Manning.jpeg").default,
    logo: require("../images/RC_how_we_do_it_icons-02.png").default,
  },
  {
    title: "Convening Power",
    description: "A stable of available members willing to provide counsel.",
    content:
      '"The group is small enough to feel intimate but large enough to have an impact."',
    author: "CMO",
    authorName: "Deirdra A. Colvin",
    authorImg: require("../images/Deirdra-Colvin.jpeg").default,
    logo: require("../images/RC_how_we_do_it_icons-04.png").default,
  },
];

export const memeberData = [
  {
    title: "Knowledge Hub",
    description:
      "Unlimited access to templates, guides, and best practices. Over 750 resources and growing",
    logo: require('../images/Knowledge-Hub.svg').default  
  },
  {
    title: "24/7 Q&A Connections",
    description:
      "In-the-moment questions answered by peers throughout the world. Take part in meaningful conversations",
    logo: require('../images/7-Connections-Over-Slack.svg').default  
  },
  {
    title: "Small Councils",
    description:
      "Curated small groups of peers meet monthly to solve problems together in a confidential setting",
    logo: require('../images/Private-Sub-Communities.svg').default  
  },
  {
    title: "Private Sub-Communities",
    description:
      "Connect with groups of peers with similar interests, like CMOs, CROs, Enterprise Leaders, and more",
    logo: require('../images/Small-Group-Councils.svg').default  
  },
  {
    title: "Benchmarking Surveys",
    description:
      "Exclusive access to real-time aggregate data on meaningful topics from throughout the community",
    logo: require('../images/Bi-weekly-Benchmarking-Surveys.svg').default  
  },
  {
    title: "Lunch Roulette",
    description:
      "Global one-to-one matching and connection to foster relationships with other members",
    logo: require('../images/Lunch-Roulette.svg').default  
  },
  {
    title: "Mentoring",
    description:
      "Find a mentor or become a mentor to a member within Auto Genius to foster new relationships",
    logo: require('../images/Mentoring-Platform.svg').default  
  },
];

export const memeberPArtData = [
  {
    title: "Partnership with Sales Impact Academy",
    description:
      "Exclusive access to the live learning platform, with instant access to over 25 courses taught by industry experts",
    logo: require('../images/Partnership-with-Sales-Impact-Academy.png').default  
  },
  {
    title: "Auto Genius Courses",
    description:
      "Access CRO School, the Rising Executives Program, and more",
    logo: require('../images/Courses.png').default  
  },
  {
    title: "Up to 30 Events Per Week",
    description:
      "From small roundtables to quarterly offsites, there are learning opportunities every single day.",
    logo: require('../images/Up-to-30-Events-Per-Week.png').default  
  },
]

export const memeberServiceData = [
  {
    title: "Career Services Hotline",
    description:
      "Instant connection to legal advisors, wealth managers, accountants, career coaches, and more",
    logo: require('../images/Legal-Hotline.svg').default  
  },
  {
    title: "Partnership with ScaleWise",
    description:
      "Exclusive access to paid consulting, coaching, and advisory opportunities for Auto Genius members only",
    logo: require('../images/Partnership-with-Sales-Impact-Academy.svg').default  
  },
  {
    title: "Recruiter Access and Job Postings",
    description:
      "Get access to our vetted network of recruiters and exclusive daily job postings (over 2,700 jobs shared in 2020)",
    logo: require('../images/Recruiters-Exclusive-Job-Postings.svg').default  
  },
  {
    title: "Executive Coaches",
    description:
      "Coaches will guide your professional development through one-to-one strategic planning and check-ins",
    logo: require('../images/Executive-Coaches.svg').default  
  },
  {
    title: "On the Bench",
    description:
      "Full suite of resources available for anyone in between roles, with dedicated support from our team",
    logo: require('../images/On-the-Bench.svg').default  
  },
  {
    title: "LinkedIn Profile and Resume Reviews",
    description:
      "Reviews from career experts who will ensure your LinkedIn and resume are the best reflection of you",
    logo: require('../images/LinkedIn-Profile-and-Resume-Reviews.svg').default  
  },
]