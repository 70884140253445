import React from "react";
import {aboutUsData} from "../../constants/Data";
import {useStaticQuery, graphql} from "gatsby";
const WhatWeDo = ({WhatWeDoTitle, WhatWeDoSubTitle}) => {
  const data = useStaticQuery(graphql`
    query WhatWeDo {
      allSanityAbout {
        edges {
          node {
            sections {
              ... on SanityAboutMulticontent {
                _key
                aboutMultiBlock {
                  title
                  description
                  logo {
                    alt
                    asset {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const WhatWeDoData =
    data.allSanityAbout.edges[0].node.sections[1].aboutMultiBlock;

  return (
    <div className="max-w-5xl pt-36 md:pt-48 px-10 md:px-2 mx-auto">
      <h1 className="billrightTitle text-center text-3xl md:text-5xl font-bold mb-20">
          {WhatWeDoTitle}
        </h1>
        <p className="text-black text-center text-lg md:text-xl pb-10 max-w-xl mx-auto">
          {WhatWeDoSubTitle}
        </p>
        <div className="w-full md:flex flex-col md:flex-row justify-between px-3 md:px-16 ">
          {WhatWeDoData.map((item, index) => {
            return (
              <div className="m-4 p-4 mb-12 md:w-1/4 md:mb-4 bg-white rounded-lg new-shadow" key={index}>
                <img
                  src={
                    item.logo.asset.url != undefined
                      ? item.logo.asset.url
                      : null
                  }
                  alt={item.title}
                  className="pt-4 max-w-16 max-h-16 mx-auto"
                />
                <h1 className="text-2xl text-black font-bold text-center pt-5 pb-3">
                  {item.title !== undefined ? item.title : null}
                </h1>
                <p className="text-sm text-black font-medium text-center pb-5">
                  {item.description !== undefined ? item.description : null}
                </p>
              </div>
            );
          })}
        </div>
      </div>
  );
};

export default WhatWeDo;
