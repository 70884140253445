import React from 'react'
import Layout from '../components/Layout';
import Index from '../components/about-us/Index'
import { Helmet } from 'react-helmet'

const aboutus = () => {
    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Auto Genius - About Auto Genius </title>
            <meta
              name="description"
              content="Auto Genius founders, Kyle Mountsier, Ben Hadley and Kevin Gervais are on a mission to enable auto marketing innovation via Slack."
            />
          </Helmet>
          <Layout>
        
        
            <Index />
        </Layout></>
    )
}

export default aboutus
