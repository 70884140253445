import React from "react";
import {aboutUsHowWeData} from "../../constants/Data";
import instantImg from "../../images/RC_how_we_do_it_icons-01.png";
import instantImg1 from "../../images/RC_how_we_do_it_icons-03.png";
import instantImg2 from "../../images/RC_how_we_do_it_icons-02.png";
import instantImg3 from "../../images/RC_how_we_do_it_icons-04.png";
import authorImg1 from "../../images/Megan-Bowen.jpeg";
import authorImg2 from "../../images/Brian-Manning.jpeg";
import authorImg3 from "../../images/Deirdra-Colvin.jpeg";
import authorImg4 from "../../images/Megan-Bowen.jpeg";
import {useStaticQuery, graphql} from "gatsby";
const HowWeDo = ({HowWeDoTitle, HowWeDoDescription}) => {
  const data = useStaticQuery(graphql`
    query HowWeDo {
      allSanityAbout {
        edges {
          node {
            sections {
              ... on SanityAboutFieldsSingleContent {
                _key
                mention
                description
                logo {
                  alt
                  asset {
                    url
                  }
                }
                name
                designation
                quote
                authorImage {
                  alt
                  asset {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const HowWeDoData = data.allSanityAbout.edges[0].node.sections;

  return (
    <div className="w-full bg-white">
      <div className="max-w-6xl mx-auto py-20 px-10 md:16">
        <h1 className="text-black pb-7 text-center text-3xl md:text-5xl font-bold">
          {HowWeDoTitle}
        </h1>
        <p className="text-black text-center text-lg md:text-xl pb-10 md:max-w-xl mx-auto">
          {HowWeDoDescription}
        </p>

        <div className="flex flex-col md:flex-row justify-center md:justify-between px-4 md:px-16 mb-10">
          <div>
            <img
              src={
                HowWeDoData[3].logo.asset.url != undefined
                  ? HowWeDoData[3].logo.asset.url
                  : null
              }
              alt={HowWeDoData[3].logo.alt}
              className="mb-3 ml-10"
            />
            <div className="whatweDo-card relative new-shadow">
              <h1 className="text-black text-xl font-bold pb-2">
                {HowWeDoData[3].mention !== undefined
                  ? HowWeDoData[3].mention
                  : null}
              </h1>
              <p className=" text-gray-800 text-md font-normal pb-0">
                {HowWeDoData[3].description !== undefined
                  ? HowWeDoData[3].description
                  : null}
              </p>
            </div>
          </div>
          <div className="md:max-w-xl">
            <p className="text-black text-lg md:px-16 md:text-2xl italic font-normal pt-20 pb-10">
              {HowWeDoData[3].quote !== undefined ? HowWeDoData[3].quote : null}
            </p>
            <div className="flex flex-row md:px-16 ">
              <div>
                <img
                  src={
                    HowWeDoData[3].authorImage.asset.url != undefined
                      ? HowWeDoData[3].authorImage.asset.url
                      : null
                  }
                  alt={HowWeDoData[3].authorImage.alt}
                  className=" w-24 md:w-36 shadow-xl rounded-full"
                />
              </div>
              <div className="pl-4 pt-4 md:pt-12">
                <p className="text-blue-500 text-base font-normal">
                  {HowWeDoData[3].name !== undefined
                    ? HowWeDoData[3].name
                    : null}
                </p>
                <p className="text-blue-500 text-base font-normal">
                  {HowWeDoData[3].designation !== undefined
                    ? HowWeDoData[3].designation
                    : null}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col-reverse md:flex-row justify-between px-3 md:px-16 mb-10">
          <div className="md:max-w-xl">
            <p className="text-black text-lg md:text-2xl italic font-normal pt-20 pb-10  md:pr-16">
              {HowWeDoData[4].quote !== undefined ? HowWeDoData[4].quote : null}
            </p>
            <div className="flex flex-row ">
              <div>
                <img
                  src={
                    HowWeDoData[4].authorImage.asset.url != undefined
                      ? HowWeDoData[4].authorImage.asset.url
                      : null
                  }
                  alt={HowWeDoData[4].authorImage.alt}
                  className="w-24 md:w-36 shadow-xl rounded-full"
                />
              </div>
              <div className="pl-4 pt-4 md:pt-12">
                <p className="text-blue-500 text-base font-normal">
                  {HowWeDoData[4].name !== undefined
                    ? HowWeDoData[4].name
                    : null}
                </p>
                <p className="text-blue-500 text-base font-normal">
                  {HowWeDoData[4].designation !== undefined
                    ? HowWeDoData[4].designation
                    : null}
                </p>
              </div>
            </div>
          </div>
          <div>
            <img
              src={
                HowWeDoData[4].logo.asset.url != undefined
                  ? HowWeDoData[4].logo.asset.url
                  : null
              }
              alt={HowWeDoData[4].logo.alt}
              className="mb-3 ml-10"
            />
            <div className="whatweDo-card relative new-shadow">
              <h1 className="text-black text-xl font-bold pb-2">
                {HowWeDoData[4].mention !== undefined
                  ? HowWeDoData[4].mention
                  : null}
              </h1>
              <p className=" text-gray-800 text-md font-normal pb-3">
                {HowWeDoData[4].description !== undefined
                  ? HowWeDoData[4].description
                  : null}
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row justify-center md:justify-between px-4 md:px-16 mb-10 ">
          <div>
            <img
              src={
                HowWeDoData[5].logo.asset.url != undefined
                  ? HowWeDoData[5].logo.asset.url
                  : null
              }
              alt={HowWeDoData[5].logo.alt}
              className="mb-3 ml-10"
            />
            <div className="whatweDo-card relative new-shadow">
              <h1 className="text-black text-xl font-bold pb-2">
                {HowWeDoData[5].mention !== undefined
                  ? HowWeDoData[5].mention
                  : null}
              </h1>
              <p className=" text-gray-800 text-md font-normal pb-3">
                {HowWeDoData[5].description !== undefined
                  ? HowWeDoData[5].description
                  : null}
              </p>
            </div>
          </div>
          <div className="max-w-xl">
            <p className="text-black text-lg md:text-2xl md:px-16 italic font-normal pt-20 pb-10">
              {HowWeDoData[5].quote !== undefined ? HowWeDoData[5].quote : null}
            </p>
            <div className="flex flex-row md:px-16 ">
              <div>
                <img
                  src={
                    HowWeDoData[5].authorImage.asset.url != undefined
                      ? HowWeDoData[5].authorImage.asset.url
                      : null
                  }
                  alt={HowWeDoData[5].authorImage.alt}
                  className=" w-24 md:w-36 shadow-xl rounded-full"
                />
              </div>
              <div className="pl-4 pt-4 md:pt-12">
                <p className="text-blue-500 text-base font-normal">
                  {HowWeDoData[5].name !== undefined
                    ? HowWeDoData[5].name
                    : null}
                </p>
                <p className="text-blue-500 text-base font-normal">
                  {HowWeDoData[5].designation !== undefined
                    ? HowWeDoData[5].designation
                    : null}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/*<div className="flex flex-col-reverse md:flex-row justify-between px-3 md:px-16 mb-10">
          <div className="md:max-w-xl">
            <p className="text-black text-lg md:text-2xl italic font-normal pt-20 pb-10  md:pr-16">
              {HowWeDoData[6].quote !== undefined ? HowWeDoData[6].quote : null}
            </p>
            <div className="flex flex-row">
              <div>
                <img
                  src={
                    HowWeDoData[6].authorImage.asset.url != undefined
                      ? HowWeDoData[6].authorImage.asset.url
                      : null
                  }
                  alt={HowWeDoData[6].authorImage.alt}
                  className="w-24 md:w-36 shadow-xl rounded-full"
                />
              </div>
              <div className="pl-4 pt-4 md:pt-12">
                <p className="text-blue-500 text-base font-normal">
                  {HowWeDoData[6].name !== undefined
                    ? HowWeDoData[6].name
                    : null}
                </p>
                <p className="text-blue-500 text-base font-normal">
                  {HowWeDoData[6].designation !== undefined
                    ? HowWeDoData[6].designation
                    : null}
                </p>
              </div>
            </div>
          </div>
          <div>
            <img
              src={
                HowWeDoData[6].logo.asset.url != undefined
                  ? HowWeDoData[6].logo.asset.url
                  : null
              }
              alt={HowWeDoData[6].logo.alt}
              className="mb-3 ml-10"
            />
            <div className="whatweDo-card relative new-shadow">
              <h1 className="text-black text-xl font-bold pb-2">
                {HowWeDoData[6].mention !== undefined
                  ? HowWeDoData[6].mention
                  : null}
              </h1>
              <p className=" text-gray-800 text-md font-normal pb-3">
                {HowWeDoData[6].description !== undefined
                  ? HowWeDoData[6].description
                  : null}
              </p>
            </div>
          </div>
        </div>*/}


      </div>
    </div>
  );
};

export default HowWeDo;
